<template>
  <b-container v-touch:swipe.left="swipeLeft">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h2 style="margin-top: 4rem; margin-bottom: 1.3rem;">Fahrrad Funktionen</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img class="image" src="@/assets/pictures/step1.png" alt="tutorial" style="width: 100%; height: 100%;"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="margin-top: 1.3rem;">Du entsperrst das Fahrrad, indem du in der App den jeweiligen Button klickst und dich in 10m Nähe zu dem Fahrrad befindest.</p>
        <p style="margin-bottom: 4rem;">Zum Anschalten des Antriebssystems halte 2 Sekunden lang den Einschalter auf dem Display gedrückt und wechsle von dem Parkmodus des Fahrrads in den Fahrmodus, indem du links dreht.</p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="bikeTutorial" :max="6" :pos="1"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <b-row>
      <NextButton text="Weiter" next @click="$router.push('/biketutorial/2')" />
    </b-row>
  </b-container>
</template>

<script>
import PageIndicator from '../components/PageIndicator';
import NextButton from '../components/NextButton';
  export default {
    name: 'BikeTutorial1',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeLeft() {
        this.$router.push('/bikeTutorial/2')
      },
      swipeRight() {
       // this.$router.push('/bikeTutorial/1')
      }
    }
  }
</script>

<style scoped>

.image {
  border-radius: 0.5rem;
}

</style>